import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/default"
import Img from "gatsby-image"
import SEO from "../components/seo"
import styled from "styled-components"
import BlogList from "../components/blogList"
import TechStacks from "../components/TechStacks"
import Wrapper from "../wrappers"

const Banner = styled.div`
  width: 100%;
  background: url("https://i.imgur.com/S0Wo59U.png");
  background-attachment: fixed;
  background-color: ${props => props.theme.primaryColor};
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  @media only screen and (max-width: 786px) {
    height: 250px;
  }
`

const Content = styled.div`
  max-width: 800px;
  margin: -30px auto 30px;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @media only screen and (max-width: 786px) {
    width: 85%;
  }
`

const BannerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  grid-gap: 20px;
  position: absolute;
  bottom: 0px;
  div:first-child {
    align-self: end;
  }
  @media only screen and (max-width: 786px) {
    bottom: 30px;
  }
`

const BannerText = styled.div`
  color: #fff;
  font-size: 34px;
  font-family: "Poppins";
  font-weight: bold;
  @media only screen and (max-width: 786px) {
    font-size: 16px;
  }
`

const Heading = styled.div`
  color: ${props => props.theme.primaryColor};
  border-bottom: 5px dotted ${props => props.theme.primaryColor};
  width: max-content;
  margin: 30px 0 50px;
  font-weight: bold;
  font-size: 30px;
  @media only screen and (max-width: 786px) {
    font-size: 22px;
    margin: 15px 0 30px;
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Banner>
        <Wrapper>
          <BannerContent>
            <div>
              <Img
                alt="Ashish Ajimal | ProllyDrunk"
                fluid={data.file.childImageSharp.fluid}
                style={{
                  mixBlendMode: "luminosity",
                }}
              />
            </div>
            <BannerText>
              Hey, I'm Ashish Ajimal.
              <br />I work with startups to hit the ground running on their
              digital journey.
            </BannerText>
          </BannerContent>
        </Wrapper>
      </Banner>
      <Content>
        <Heading>Recent Articles</Heading>
        <BlogList recent />
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        author
      }
    }
    file(relativePath: { eq: "mugshot.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 290
          maxHeight: 300
          traceSVG: {
            color: "#D61A3C"
            turnPolicy: TURNPOLICY_MAJORITY
            blackOnWhite: true
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
