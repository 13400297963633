import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  grid-template-columns: 1fr 1fr;
  display: grid;
  align-items: center;
  grid-gap: 50px;
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 786px) {
    width: 90%;
  }
`

const Content = styled.div`
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
`

const Title = styled.h3`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.theme.primaryColor};
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px;
  text-align: center;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px 0px;
  @media only screen and (max-width: 786px) {
    font-size: 12px;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    {
      web: file(relativePath: { eq: "website.png" }) {
        childImageSharp {
          fluid(
            maxHeight: 250

            traceSVG: {
              color: "#D61A3C"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      mobile: file(relativePath: { eq: "application.png" }) {
        childImageSharp {
          fluid(
            maxHeight: 250
            traceSVG: {
              color: "#D61A3C"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Content>
        <Img
          alt="Web Development"
          fluid={data.web.childImageSharp.fluid}
          style={{
            opacity: 0.5,
            mixBlendMode: "luminosity",
          }}
        />
        <Title className="stack-title">Web Apps</Title>
      </Content>
      <Content>
        <Img
          alt="Mobile App Development"
          fluid={data.mobile.childImageSharp.fluid}
          style={{
            opacity: 0.5,
            mixBlendMode: "luminosity",
          }}
        />
        <Title className="stack-title">Mobile Apps</Title>
      </Content>
    </Container>
  )
}
